import { graphql } from "gatsby"
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'

// Components
import SEO from '../components/organisms/seo'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import Masthead from "../components/organisms/masthead"
import Paragraph1 from "../components/atoms/paragraph-1"
import Header1 from "../components/atoms/header-1"

// Styles
const Container = styled.section`

`
const ContainerOverview = styled.section`
  display: flex;
  padding: 15px;
  background: #F1F0EE;
  width: auto;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
const ContainerOverviewImage = styled.section`
  width: 45%;
  margin: 150px 0;
  @media (max-width: 768px) {
    width: 100%;
    order: 2;
    margin: 15px 0 30px 0;
  }
`
const ContainerOverviewInfo = styled.section`
  width: 450px;
  padding: 0 150px;
  @media (max-width: 768px) {
    order: 1;
    padding: 0 15px;
    width: 100%;
  }
`
const ContainerGallery = styled.section`
  width: calc(100% - 10px);
  padding: 5px;
  margin: 150px auto 150px auto;
  max-width: 1440px;
  > p {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    width: 100%;
    &:last-child {
      margin: 0;
    }
    > span {
      min-height: 400px;
      max-height: 1000px;
      width: 100%;
      overflow: hidden;
      &:last-child {
        margin: 0;
      }
      > span {
        height: 100%;
        width: 100%;
      }
      img {
        object-fit: cover;
      }
      @media (max-width: 768px) {
        width: 100%;
        min-height: initial;
      }
    }
    > span:nth-of-type(3n+1) {
      max-height: 600px;
      grid-column: span 2;
      &:last-child {
        margin: 0;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    > a:nth-of-type(3n+2) {
      max-height: 600px;
      margin: 0 0px 5px 5px;
      width: calc(50% - 2.5px);
      margin-bottom: 5px;
      &:last-child {
        margin: 0 0px 0px 5px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 768px) {
    margin-top: 30px;
    width: auto;
    grid-template-columns: 1fr;
  }
`

export default ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter

  return (
    <Layout>
      <SEO title={frontmatter.tagTitle} keywords={[`landscaping`, `landscape architecture`, `catharine ann farnen llc`, `pennsylvania landscaping`, `${frontmatter.tagTitle}`, `${frontmatter.tagType}`]} />
      <Masthead 
        mastheadImage={<Img css={css`height: 100%; width: 100%;`} alt={`${frontmatter.tagTitle} ${frontmatter.tagType} catharine ann farnen landscape architecting`} fluid={frontmatter.mastheadImage.childImageSharp.fluid} critical />} 
        tagImage={<Img css={css`height: 100%; width: 100%;`} alt={`${frontmatter.tagTitle} ${frontmatter.tagType} catharine ann farnen landscape architecting tag`} fluid={frontmatter.tagImage.childImageSharp.fluid} critical />}
        tagTitle={frontmatter.tagTitle}
        tagType={frontmatter.tagType}
        fill1={'#F1F0EE'}
        fill2={'#FFFFFF'}
        fill2Opacity={'1'}
      />
      <Container>
        <ContainerOverview>
          <ContainerOverviewImage>
            <Img css={css`height: 100%; width: 100%;`} alt={`${frontmatter.tagTitle} ${frontmatter.tagType} catharine ann farnen landscape architecting overview`} fluid={frontmatter.overviewImage.childImageSharp.fluid} />
          </ContainerOverviewImage>
          <ContainerOverviewInfo>
            <Header1>{frontmatter.overviewTitle}</Header1>
            <Paragraph1 innerHTML={{__html: frontmatter.overviewText}}/>
          </ContainerOverviewInfo>
        </ContainerOverview>
        <ContainerGallery dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        mastheadImage {
          childImageSharp {
            fluid(
              maxWidth: 1920
            ) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 1200) {
              src
            }
          }
        }
        tagImage {
          childImageSharp {
            fluid(
              maxWidth: 200
            ) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 200) {
              src
            }
          }
        }
        tagTitle
        tagType
        overviewImage {
          childImageSharp {
            fluid(
              maxWidth: 1440
            ) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 1440) {
              src
            }
          }
        }
        overviewTitle
        overviewText
      }
    }
  }
`